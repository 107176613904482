.book-view-container {
  width: 90%;
  margin: auto;
  padding: 1rem;
  border: 1px solid black;
  background-color: wheat;
}

.book-view-header-container {
  display: flex;
  padding: 1rem 0;
}

.book-view-header-container .book-title {
  flex: 1;
  font-size: 2rem;
  font-weight: bold;
}

.book-view-header-container .book-edit-button {
  background-color: rgb(248, 232, 203);
  text-align: center;
  padding: 0 0.7rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 7px;
}

.book-view-container .book-published-date {
  font-style: italic;
  margin-bottom: 2rem;
}

.book-view-container .book-short-description {
  margin: 1rem;
}