.add-book-container {
  margin-top: 2rem;
}

.add-book-container h1 {
  margin: 1rem 0;
}

.add-book-container input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.add-book-container textarea {
  width: 100%;
  height: 10rem;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  overflow: auto;
}

.add-book-container .submit-button {
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.add-book-container .cancel-button {
  background-color: #858a85;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.add-book-container .submitting-data {
  color: green;
  text-align: center;
  padding: 1rem;
}

.add-book-container .submit-fail {
  color: red;
  text-align: center;
  padding: 1rem;
}