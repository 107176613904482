.entry-view-container {
  width: 90%;
  margin: auto;
  padding: 1rem;
  border: 1px solid black;
  background-color: wheat;
}

.entry-view-container .entry-header-container {
  display: flex;
}

.entry-header-container .breadcrumb-text {
  flex: 1;
  font-weight: bold;
}

.entry-header-container .entry-edit-button {
  background-color: thistle;
  text-align: center;
  padding: 0.7rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 7px;
}

.entry-view-container .spoiler-level-warning {
  margin: 2rem;
  font-size: larger;
  font-weight: bold;
  font-style: italic;
}

.entry-view-container .entry-html {
  margin: 1rem;
}

.entry-view-container .entry-html h1 {
  margin: 1rem 0;
}

.entry-view-container .entry-html p {
  margin: 1rem 0;
}

.entry-view-container .entry-html a {
  text-decoration: underline;
  color: blue;
}