.navigation-frame {
  grid-area: navigation;

}

.title-box {
  padding: 0.75rem;
  margin: 1rem;
  font-size: 1rem;
  text-align: left;
  
  color: black;
  background-color: tomato;
  border-radius: 5px;
}

.nav-option {
  padding: 0.5rem;
  margin: 1rem;
  font-size: 2rem;
  text-align: left;
  cursor: pointer;
  
  color: black;
  border-radius: 5px;
}


