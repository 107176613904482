.App {
  width: 100%;
  display: grid;
  grid-template-columns: 33% 33% 34%;
  grid-template-areas:
  "header  header  header"
  "navigation content content"
  "footer  footer  footer";

}

.loading-banner {
  background-color: bisque;
  text-align: center;
  height: 1.5rem;
}