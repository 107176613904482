.series-view-container {
  width: 90%;
  margin: auto;
  padding: 1rem;
  border: 1px solid black;
  background-color: wheat;
}

.series-view-header-container {
  display: flex;
  padding: 0.5rem;
}

.series-view-container .series-view-header-title {
  flex: 1;
  font-size: 2rem;
  font-weight: bold;
}

.series-view-container .series-edit-button {
  background-color: thistle;
  text-align: center;
  padding: 0.7rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 7px;
}

.series-view-container .series-description {
  font-style: italic;
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.series-view-container .book-line-item {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  width: 100%;
}

.series-view-container .book-line-item input {
  width: 3rem;
  cursor: pointer;
  margin: 0.4rem 0;
}

.series-view-container .book-title {
  font-weight: bold;
}

.series-view-container .book-date {
  font-style: italic;
}

.series-view-container .add-book-button {
  margin: 1rem 0 0 1rem;
}