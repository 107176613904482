.add-series-container {
  width: 75%;
  margin: auto;
  border: 1px solid black;
  background-color: wheat;
}

.add-series-container .add-series-title {
  color: #444;
  margin: 1rem;
  padding: 0;
  font: small-caps bold 2rem sans-serif;
  text-align: center;
}