.mention-list {
  margin-top: 1rem;
}

.mention-list .mention-list-header {
  margin-bottom: .5rem;
}

.mention-list .add-mention-button {
 margin: 1rem 0;
}

.mention-list .new-entry-item {
  color:red;
  font-weight: bold;
}

.mention-list .new-entry-item:hover {
  background-color:burlywood;
}

.mention-list .entry-item:hover {
  background-color:burlywood;
}