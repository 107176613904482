.account-container {
  width: 50%;
  margin: auto;
}

.account-frame {

  font: bold 1rem Verdana ;
  text-align: left;
  
  color: black;
  background-color: rgb(100, 100, 100);
  border-radius: 1px;

}

.info-item {
  width: 100%;
  padding: 1rem;
  display: inline-block;
  box-sizing: border-box;
  color:wheat;
}

.logout-button {
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  display: inline-block;
  box-sizing: border-box;
  font: bold 1rem Verdana ;
  text-align: center;
  border-radius: 10px;
  
  color: black;
  background-color: rgb(64, 201, 255);
  cursor: pointer;
}