.login-container {
  width: 75%;
  margin: auto;
  border: 1px solid black;
}

.login-container .tab-container {
  display: flex;
}

.login-container .login-login-tab {
  color: #444;
  background-color: wheat;
  margin: 0;
  padding: 0;
  font: small-caps bold 2rem sans-serif;
  text-align: center;
  flex: 1;
}

.login-container .login-signup-tab {
  background-color: grey;
  color: #444;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font: small-caps bold 2rem sans-serif;
  text-align: center;
  flex: 1;
}

.login-container .form-box {
  padding: 1rem;
  background-color: wheat;
}

.login-container input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.login-container .login-button {
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.login-container .cancel-button {
  background-color: #858a85;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.login-container .login-fail {
  color: red;
  text-align: center;
  padding: 1rem;
}

.login-container .buttonValidate:invalid {
  background-color: pink;
}

.login-container button:hover {
  opacity: 0.8;
}

/* The "Forgot password" text */
.login-container span.psw {
  float: right;
  padding-top: 16px;
}